.accreditation {
  flex-wrap: wrap;
  justify-content: flex-end;
}

.masterNum {
  font-size: 14px;
}

.qualifImage {
  height: 120px;
  width: auto;
}

.generalInfoCard {
  margin: auto;
  margin-top: 100px !important;
  margin-bottom: 50px;
}

/* @media screen and (max-width: 1100px) {
} */

.rowLogoCar {
  display: flex;
  flex-wrap: nowrap;
}

ul li {
  font-size: 19px;
}

.logoGeneralCard {
  margin-left: -130px !important;
}

@media screen and (max-width: 2000px) {
  .logoGeneralCard {
    margin-left: -120px !important;
  }
}

@media screen and (max-width: 2000px) {
  .logoGeneralCard {
    margin-left: -100px !important;
  }
}

@media screen and (max-width: 1400px) {
  .logoGeneralCard {
    margin-left: -60px !important;
  }
}

@media screen and (max-width: 1200px) {
  h5 {
    font-size: 17px;
  }
  ul li {
    font-size: 16px;
  }
}

@media screen and (max-width: 1000px) {
  ul li {
    font-size: 17px;
  }
  .qualifImage {
    height: 90px;
  }
  .masterNum {
    font-size: 9px;
  }
}

@media screen and (max-width: 775px) {
  h5 {
    font-size: 16px;
  }
  ul li {
    font-size: 15px;
  }
  .generalInfoCard {
    width: 80% !important;
  }
  .qualifImage {
    height: 70px;
  }
  .masterNum {
    font-size: 8px;
  }
  .rowLogoCar {
    flex-direction: column-reverse;
  }
  .logoGeneralCard {
    margin: auto !important;
  }
}
@media screen and (max-width: 767px) {
  .masterImage {
    margin-right: 10px;
  }
}

@media screen and (max-width: 600px) {
  .qualifImage {
    height: 65px;
  }
  .masterNum {
    font-size: 6px;
    width: 40px;
  }
}

@media screen and (max-width: 500px) {
  h5 {
    font-size: 15px;
  }
  ul li {
    font-size: 14px;
  }
  .rowLogoCar {
    margin-top: 20px;
  }
}

@media screen and (max-width: 400px) {
  h5 {
    font-size: 14px;
  }
  ul li {
    font-size: 13px;
  }
  ul {
    padding-left: 15px;
  }
  .logoGeneralCard {
    height: 120px;
    width: 200px !important;
  }
}

@media screen and (max-width: 300px) {
  h5 {
    font-size: 13px;
  }
  ul li {
    font-size: 12px;
  }
}
