.externalDiv {
  min-height: 100vh;
  display: flex;
  /* justify-content: center;
  align-items: center; */
}

.BlogContainer {
  position: relative;
  /* width: 100%; */
  overflow: hidden;
  /* height: 100%; */
}

.titleArticle {
  font-size: 25px;
}

.columnsArticle h3, h4 {
  margin-bottom: 20px;
  margin-top: 20px;
}
.columnsArticle {
  column-count: 2;
  font-size: 20px;
}

.columnsArticle h4 {
  font-size: 17px;
  text-decoration: underline;
}

.columnsArticle h3 {
  font-size: 18px;
  text-decoration: underline;
}

.backgroundImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed; */
  z-index: -1;
  background-position: center;
  /* height: 100%; */
}

.blogCard {
  display: flex;
  width: 100%;
}

.linkToArticle {
  font-size: 25px;
}

.blogSumImage {
  height: 200px;
  width: 200px;
  background-size: cover;
}

.titleCol {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
}

.imageSpecificBlog {
  /* width: 60%; */
  height: 500px;
  margin: auto;
  display: flex;
}

.textBlog {
  width: 60% !important;
  display: flex;
  margin: auto;
  margin-bottom: 50px;
}

.titleBlog {
  margin-top: 100px !important;
  margin-bottom: 30px !important;
  display: flex;
  /* margin: auto; */
  justify-content: space-around;
  font-size: 35px;
  /* outline: 0.5rem solid; */
  width: 60%;
  margin: auto;
  border-top: 5px solid black;
  border-left: 5px solid black;
}

@media screen and (max-width: 1000px) {
  .titleBlog {
    font-size: 30px;
  }
  .linkToArticle {
    font-size: 20px;
  }
  .imageSpecificBlog {
    height: 400px;
  }
  .columnsArticle p {
    font-size: 17px;
  }
}

@media screen and (max-width: 775px) {
  .titleBlog {
    font-size: 28px;
    width: 80% !important;
  }
  .textBlog {
    font-size: 15px;
    width: 80% !important;
  }
  .linkToArticle {
    font-size: 19x;
  }
  .imageSpecificBlog {
    height: 200px;
  }
  .columnsArticle p {
    font-size: 15px;
  }
  .columnsArticle h3 {
    font-size: 17px;
  }
  .columnsArticle h4 {
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  .titleBlog {
    font-size: 25px;
  }
  .textBlog {
    font-size: 12px;
  }
  .columnsArticle p {
    font-size: 12px;
  }

  .columnsArticle h3 {
    font-size: 15px;
  }
  .columnsArticle h4 {
    font-size: 14px;
  }
}

@media screen and (max-width: 510px) {
  .titleBlog {
    font-size: 22px;
  }
  .linkToArticle {
    font-size: 17px;
  }
  .columnsArticle {
    column-count: 1;
    /* text-align: justify; */
  }
  .titleArticle {
    font-size: 22px;
  }

}

@media screen and (max-width: 425px) {
  .titleBlog {
    font-size: 18px;
  }
  .textBlog {
    font-size: 10px;
  }
  .columnsArticle p {
    font-size: 10px;
  }
  .linkToArticle {
    font-size: 15px;
  }
  .titleArticle {
    font-size: 18px;
  }
  
}

@media screen and (max-width: 350px) {
  .titleBlog {
    font-size: 15px;
  }
  .textBlog {
    font-size: 9px;
  }
  .columnsArticle p {
    font-size: 9px;
  }
  .linkToArticle {
    font-size: 10px;
  }
  .columnsArticle h3 {
    font-size: 11px;
  }
  .columnsArticle h4 {
    font-size: 10px;
  }
  .titleArticle {
    font-size: 15px;
  }
}
