.blogButton {
  color: black;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.4);
  margin: auto;
}

.blogTitle {
  font-size: 25px;
  font-weight: bold;
}
.blogDescription {
  font-size: 20px;
}

.carousel-caption {
  bottom: 10px !important;
  padding-bottom: 0px !important;
}

.carousel-indicators {
  visibility: hidden;
}

.carouselCard {
  margin: auto;
  margin-bottom: 20px;
}

.blogButton:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.blogButton:active {
  background-color: rgb(134, 155, 134) !important;
}

/* .carButtons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
} */

@media screen and (max-width: 2000px) {
  .blogTitle {
    font-size: 18px;
    margin-bottom: -4px;
    margin-top: -5px;
  }
  .blogDescription {
    font-size: 17px;
    margin-bottom: -5px;
  }
}

@media screen and (max-width: 1100px) {
  .blogTitle {
    font-size: 15px;
  }
}

@media screen and (min-width: 1000px) {
  .carouselCard {
    margin-top: 50px;
  }
}

@media screen and (max-width: 1000px) {
  .blogDescription {
    font-size: 13px;
  }
  .carouselCard {
    margin-top: 50px;
  }
  .blogTitle {
    font-size: 13px;
  }
}

@media screen and (min-width: 775px) {
  .carouselMargin {
    margin-left: 20px;
    margin-top: -10px;
  }
}

@media screen and (max-width: 775px) {
  .carouselCard {
    width: 95% !important;
  }
  .carouselTitle {
    font-size: 12px;
  }
  .carouselText {
    font-size: 10px;
  }
  .carouselMargin {
    margin-top: -50px;
  }
  .blogTitle {
    font-size: 20px;
  }
}

@media screen and (max-width: 600px) {
  .blogButton {
    font-size: 15px;
  }
  .blogDescription {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .blogTitle {
    font-size: 18px;
  }
}

@media screen and (max-width: 490px) {
  .blogTitle {
    font-size: 16px;
  }
}

@media screen and (max-width: 430px) {
  .blogTitle {
    font-size: 15px;
  }
}

@media screen and (max-width: 400px) {
  .blogButton {
    font-size: 12px;
  }
  .blogTitle {
    font-size: 14px;
  }
}

@media screen and (max-width: 383px) {
  .blogTitle {
    font-size: 11px;
  }
}

@media screen and (max-width: 300px) {
  .blogTitle {
    font-size: 10px;
  }
}
