.dropdown-menu {
  background-color: rgb(33, 37, 41, 0.9);
  padding-left: 10px;
  color: rgb(182, 180, 180);
  margin-left: -10px;
}

.dropdown-menu a {
  color: rgb(182, 180, 180);
  font-size: 20px;
}

.dendroTitle {
  font-size: 24px;
}

.dropdownServices {
  color: rgb(182, 180, 180);
}

.dendroNavbar {
  display: flex;
  margin-right: 20px;
  text-decoration: none;
  color: rgb(182, 180, 180) !important;
  font-size: 20px;
}

.dropdown-item:active {
  background-color: #9ab07b;
}

.dendroNavbar:hover {
  text-decoration: underline !important;
}

@media screen and (max-width: 339px) {
  .dendroTitle {
    font-size: 20px;
    display: flex;
    align-items: center;
  }
  .dendroNavbar {
    font-size: 18px;
  }
  .dropdown-menu a {
    font-size: 18px;
  }
}

@media screen and (max-width: 308px) {
  .navbar-toggler {
    width: 40px;
  }
  .navbar-toggler-icon {
    height: 20px;
    width: 20px;
  }
  .dendroTitle {
    font-size: 20px;
    display: flex;
    align-items: center;
  }
  .dropdown-menu a {
    font-size: 17px;
  }
  .dendroNavbar {
    font-size: 17px;
  }
  .logoNavBar {
    margin-left: -10px;
  }
}

@media screen and (max-width: 282px) {
  .dendroTitle {
    font-size: 17px;
    display: flex;
    align-items: center;
  }
  .dendroNavbar {
    font-size: 16px;
  }
  .dropdown-menu a {
    font-size: 16px;
  }
}

@media screen and (max-width: 260px) {
  .dendroTitle {
    font-size: 15px;
    display: flex;
    align-items: center;
  }
}
