.SoilCareContainer {
  position: relative;
  /* width: 100%; */
  overflow: hidden;
}

.backgroundImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: -1;
  background-position: center;
}

.textSoil {
  width: 60%;
  display: flex;
  margin-bottom: 50px !important;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.7);
}

.mainImageSoil {
  width: 60%;
  /* height: 60vh; */
  display: flex;
  margin: auto;
  height: 100%;
}

.soilImages {
  width: 40%;
  display: flex;
  margin: auto;
}

.titleSoil {
  margin-bottom: 30px !important;
  display: flex;
  /* margin: auto; */
  justify-content: space-around;
  font-size: 45px;
  /* outline: 0.5rem solid; */
  width: 60%;
  margin: auto;
  border-top: 5px solid black;
  border-left: 5px solid black;
  margin-top: 100px !important;
}

.linksPlantHealth {
  text-decoration: none;
  font-size: 15px;
  /* margin-bottom: 100px !important; */
  border-bottom: 2px solid black;
  border-left: 2px solid black;
  color: black;
  font-weight: bold;
}

.learnMoreButton {
  position: absolute;
  bottom: 5px;
  right: 5px;
  /* transform: translate(-50%, -50%); */
  /* -ms-transform: translate(-50%, -50%); */
  background-color: #9ab07b;
  color: black;
  /* font-weight: bolder; */
  border: none;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.cardTitleSoil {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.learnMoreButton:hover {
  background-color: rgb(134, 155, 134);
  color: black;
}
@media screen and (max-width: 1000px) {
  .titleSoil {
    font-size: 40px;
  }
  .learnMoreButton {
    font-size: 10px;
  }
}

@media screen and (max-width: 775px) {
  .textSoil {
    font-size: 15px;
    width: 80%;
  }
  .learnMoreButton {
    font-size: 9px;
  }
  .soilImages {
    width: 80%;
  }
  .titleSoil {
    width: 80%;
    font-size: 35px;
  }
  .mainImageSoil {
    width: 80%;
    height: auto;
  }
}

@media screen and (max-width: 600px) {
  .titleSoil {
    font-size: 30px;
  }
  .learnMoreButton {
    font-size: 8px;
  }
  .linksPlantHealth {
    font-size: 14px;
  }
}

@media screen and (max-width: 510px) {
  .titleSoil {
    font-size: 25px;
  }
  .textSoil {
    font-size: 12px;
  }
  .linksPlantHealth {
    font-size: 13px;
  }
}

@media screen and (max-width: 400px) {
  .titleSoil {
    font-size: 20px;
  }
  .textSoil {
    font-size: 10px;
  }
  .learnMoreButton {
    font-size: 7px;
  }
  .linksPlantHealth {
    font-size: 12px;
  }
  .cardTitleSoil {
    font-size: 15px;
  }
}

@media screen and (max-width: 350px) {
  .titleSoil {
    font-size: 17px;
  }
  .textSoil {
    font-size: 9px;
  }
  .linksPlantHealth {
    font-size: 10px;
  }
  .cardTitleSoil {
    font-size: 12px;
  }
}
