.footer {
  background-color: #212529;
  color: white;
  text-align: center;
  padding: 0px 0;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-text {
  font-size: 15px;
  padding: 5px 0;
}

.footerIcons {
  height: 35px;
  width: 35px;
  margin-bottom: 5px;
}

.icons {
  display: flex;
  list-style-type: none;
  justify-content: center;
  padding-left: 0px;
}

@media screen and (max-width: 1000px) {
  .footerIcons {
    height: 30px;
    width: 30px;
  }
  .footer-text {
    font-size: 12px;
  }
}

@media screen and (max-width: 600px) {
  .footerIcons {
    height: 25px;
    width: 25px;
  }
  .footer-text {
    font-size: 10px;
  }
}

@media screen and (max-width: 400px) {
  .footerIcons {
    height: 20px;
    width: 20px;
  }
  .footer-text {
    font-size: 9px;
  }
}
