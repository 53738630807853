.phoneBtnDiv {
  /* position: fixed;
  bottom: 130px;
  left: 100px; */
  z-index: 10000; /* Make sure the button is above other content */
  background-color: #9ab07b;
  margin-bottom: 10px;
}

.phoneBtn {
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.phoneFloatContainer {
  position: relative; /* Ensure relative positioning */
  z-index: 9999; /* Higher z-index to ensure the button appears above other content */
  position: fixed;
  bottom: 100px;
  left: 80px;
  display: flex;
  flex-direction: column;
}

.phoneBtnDiv:hover {
  background-color: rgb(134, 155, 134);
}

.phoneBtnDiv:active {
  background-color: rgb(134, 155, 134) !important;
}

@media screen and (max-width: 1000px) {
  .phoneBtn {
    height: 30px;
    width: 30px;
  }
  .phoneFloatContainer {
    left: 50px;
  }
}
@media screen and (max-width: 775px) {
  .phoneBtn {
    height: 20px;
    width: 20px;
  }
  .phoneFloatContainer {
    left: 20px;
  }
}
@media screen and (max-width: 700px) {
  .phoneFloatContainer {
    left: 10px;
  }
  .phoneBtn {
    height: 18px;
    width: 18px;
  }
}
@media screen and (max-width: 600px) {
  .phoneBtn {
    height: 13px;
    width: 13px;
  }
}

@media screen and (max-width: 400px) {
  .phoneBtn {
    height: 15px;
    width: 15px;
  }
  .phoneFloatContainer {
    left: 10px;
  }
}

@media screen and (max-width: 300px) {
  .phoneBtn {
    height: 15px;
    width: 15px;
  }
  .phoneFloatContainer {
    left: 0px;
  }
}
