.plantHealthContainer {
  position: relative;
  /* width: 100%; */
  overflow: hidden;
}

.backgroundImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: -1;
  background-position: center;
}

.imgServiceCards {
  border-radius: 20px;
}

.servicesCard {
  margin: auto;
  margin-bottom: 50px;
  background-color: rgba(255, 255, 255, 0.7);
}

.imageCardsServ {
  display: flex;
  margin-bottom: 20px;
}

.cardBlock {
  /* background-color: rgba(255, 255, 255, 0.2); */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  margin-left: 10px;
  margin-right: 10px;
  /* display: flex; */
  align-items: stretch;
}

.learnMoreButtonServices {
  margin-right: 30px;
  position: absolute;
  bottom: 20px;
  right: 5px;
  background-color: #9ab07b;
  border: none;
  font-size: 18px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.servicesTitle {
  font-size: 40px;
}

.learnMoreButtonServices:hover {
  background-color: rgb(134, 155, 134) !important;
  color: black;
}

.learnMoreButtonServices:active {
  background-color: rgb(134, 155, 134) !important;
  color: black;
}

.serviceCardTitle {
  font-size: 25px;
  margin-bottom: 20px;
}

.serviceCardDescription {
  font-size: 20px;
  margin-bottom: 60px;
}

@media screen and (max-width: 1300px) {
  .serviceCardDescription {
    font-size: 18px;
  }
}

@media screen and (max-width: 1000px) {
  .serviceCardDescription {
    font-size: 15px;
  }
  .serviceCardTitle {
    display: flex;
    justify-content: space-around;
  }
  .pestsButton {
    font-size: 10px;
  }
  .learnMoreButtonServices {
    font-size: 14px;
  }
}

@media screen and (max-width: 775px) {
  .serviceCardDescription {
    margin-bottom: 40px;
  }

  .pestsButton {
    font-size: 9px;
  }
  .servicesCard {
    width: 80% !important;
  }
}

@media screen and (max-width: 600px) {
  .serviceCardTitle {
    font-size: 20px;
  }
  .pestsButton {
    font-size: 8px;
  }
  .servicesTitle {
    font-size: 25px;
  }
}

@media screen and (max-width: 400px) {
  .serviceCardDescription {
    font-size: 12px;
  }
  .learnMoreButtonServices {
    margin-right: 10px;
  }
  .serviceCardTitle {
    display: flex;
    justify-content: space-around;
  }
  .pestsButton {
    font-size: 7px;
  }
}
