.commonPestsContainer {
  position: relative;
  /* width: 100%; */
  overflow: hidden;
}

.backgroundImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: -1;
  background-position: center;
}

.pestsButton {
  /* position: absolute; */
  /* bottom: 5px;
  right: 5px; */
  /* transform: translate(-50%, -50%); */
  /* -ms-transform: translate(-50%, -50%); */
  background-color: #9ab07b;
  color: black;
  /* font-weight: bolder; */
  border: none;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  margin: auto;
  margin-bottom: 20px !important;
}

.pestsButton:hover {
  background-color: rgb(134, 155, 134);
  color: black;
}
