.pestsButton {
  /* position: absolute;
  bottom: 5px;
  right: 5px; */
  /* transform: translate(-50%, -50%); */
  /* -ms-transform: translate(-50%, -50%); */
  background-color: #9ab07b;
  color: black;
  /* font-weight: bolder; */
  border: none;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
}

.citeText {
  font-size: 0.7rem;
}

.pestsButton:hover {
  background-color: rgb(134, 155, 134);
  color: black;
}

.pestsButton:active {
  background-color: rgb(134, 155, 134) !important;
  color: black;
}

.pestsCard {
  margin: auto;
  /* margin-bottom: 50px; */
  /* background-color: rgba(255, 255, 255, 0.7); */
}
.cardBlock {
  /* background-color: rgba(255, 255, 255, 0.2); */
  box-shadow: 0 50px 30px rgba(0, 0, 0, 0.1);
}

.pestsTitle {
  margin-bottom: 30px !important;
  display: flex;
  /* margin: auto; */
  justify-content: space-around;
  font-size: 45px;
  /* outline: 0.5rem solid; */
  /* width: 60%; */
  margin: auto;
  border-top: 5px solid black;
  border-left: 5px solid black;
  margin-top: 100px !important;
}

@media screen and (max-width: 1000px) {
  .pestsButton {
    font-size: 10px;
  }
  .pestsTitle {
    font-size: 40px;
  }
  .citeText {
    font-size: 0.5rem;
  }
}

@media screen and (max-width: 775px) {
  .pestsButton {
    font-size: 9px;
  }
  .pestsCard {
    width: 80% !important;
  }
  .pestsTitle {
    /* width: 80%; */
    font-size: 35px;
  }
}

@media screen and (max-width: 600px) {
  .pestsButton {
    font-size: 8px;
  }
  .pestsTitle {
    font-size: 30px;
  }
  .citeText {
    font-size: 0.4rem;
  }
}

@media screen and (max-width: 510px) {
  .pestsTitle {
    font-size: 25px;
  }
}

@media screen and (max-width: 400px) {
  .pestsButton {
    font-size: 7px;
  }
  .pestsTitle {
    font-size: 20px;
  }

}
