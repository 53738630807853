.logoContact {
  /* height: 500px;
  width: 900px; */
  width: 100%;
  margin: auto;
  display: flex;
  margin-top: 100px !important;
}

.contactContainer {
  position: relative;
  overflow: hidden;
}

.backgroundImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: -1;
  background-position: center;
}

.listItems {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: baseline;
  /* font-size: 20px; */
  /* border: none; */
}

.contactCard {
  /* margin-top: 100px !important; */
  /* width: 25rem; */
  display: flex;
  justify-content: space-around;
  margin: auto;
  width: 60%;
  border-radius: 30px;
  margin-bottom: 50px !important;
  background-color: rgba(255, 255, 255, 0.7);
}

@media screen and (max-width: 775px) {
  .contactCard {
    width: 80%;
  }
}

@media screen and (max-width: 504px) {
  .logoContact {
    height: 170px;
    width: 300px !important;
  }
  .listItems img {
    height: 20px;
    width: 20px;
  }
  .listItems {
    font-size: 12px;
  }
  .textContact {
    font-size: 15px;
  }
}

@media screen and (max-width: 400px) {
  .logoContact {
    height: 150px;
    width: 270px !important;
  }
  .listItems {
    font-size: 10px;
  }
  .textContact {
    font-size: 14px;
  }
}

@media screen and (max-width: 314px) {
  .logoContact {
    height: 130px;
    width: 240px !important;
    margin-top: 80px !important;
    margin-bottom: 50px;
  }
  .listItems {
    font-size: 9px;
  }
  .textContact {
    font-size: 12px;
  }
  .contactCard {
    width: 80%;
  }
  .contactForm {
    width: 80% !important;
  }
}
