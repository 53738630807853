.plantHealthContainer {
  position: relative;
  /* width: 100%; */
  overflow: hidden;
}

.backgroundImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: -1;
  background-position: center;
}

.textPlantHealth {
  width: 60%;
  display: flex;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.7);
  margin-bottom: 50px !important;
  font-size: 18px;
}
.textPlantHealth ul li {
  font-size: 18px;
}

.plantHealthImages {
  width: 60%;
  display: flex;
  margin: auto;
}

.titlePlantHealth {
  margin-bottom: 30px !important;
  display: flex;
  /* margin: auto; */
  justify-content: space-around;
  font-size: 45px;
  /* outline: 0.5rem solid; */
  width: 60%;
  margin: auto;
  border-top: 5px solid black;
  border-left: 5px solid black;
  margin-top: 100px !important;
}

.linksPlantHealth {
  text-decoration: none;
  font-size: 19px !important;
  /* margin-bottom: 100px !important; */
  border-bottom: 2px solid black;
  border-left: 2px solid black;
  color: black;
  font-weight: bold;
}

.linksPlantHealth:hover {
  color: #9ab07b !important;
}

@media screen and (max-width: 1000px) {
  .titlePlantHealth {
    font-size: 40px;
  }
}

@media screen and (max-width: 775px) {
  .textPlantHealth {
    font-size: 15px;
    width: 80%;
  }
  .textPlantHealth ul li {
    font-size: 15px;
  }
  .plantHealthImages {
    width: 80%;
  }
  .titlePlantHealth {
    width: 80%;
    font-size: 35px;
  }
}

@media screen and (max-width: 600px) {
  .titlePlantHealth {
    font-size: 30px;
  }
  .linksPlantHealth {
    font-size: 14px;
  }
}

@media screen and (max-width: 510px) {
  .titlePlantHealth {
    font-size: 25px;
  }
  .textPlantHealth {
    font-size: 12px;
  }
  .textPlantHealth ul li {
    font-size: 12px;
  }
  .linksPlantHealth {
    font-size: 13px;
  }
}

@media screen and (max-width: 400px) {
  .titlePlantHealth {
    font-size: 20px;
  }
  .textPlantHealth {
    font-size: 10px;
  }
  .textPlantHealth ul li {
    font-size: 10px;
  }
  .linksPlantHealth {
    font-size: 12px;
  }
}

@media screen and (max-width: 350px) {
  .titlePlantHealth {
    font-size: 17px;
  }
  .textPlantHealth {
    font-size: 9px;
  }
  .textPlantHealth ul li {
    font-size: 9px;
  }
  .linksPlantHealth {
    font-size: 10px;
  }
}
