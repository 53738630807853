.aboutUsContainer {
  position: relative;
  /* width: 100%; */
  overflow: hidden;
}

.logoAboutUs {
  /* height: 500px;
  width: 700px;
  display: flex; */
  /* margin: auto; */
}

.coiButton {
  /* margin-right: 30px; */
  margin: auto;
  /* margin-top: 20px; */
  /* position: absolute; */

  /* transform: translate(-50%, -50%); */
  /* -ms-transform: translate(-50%, -50%); */
  background-color: #9ab07b;
  color: black;
  /* font-weight: bolder; */
  border: none;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  margin-bottom: 20px;
  font-weight: bold;
}

.coiButton:hover {
  background-color: rgb(134, 155, 134) !important;
}

.backgroundImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: -1;
  background-position: center;
}

.masterNumber {
  margin-bottom: 10px !important;
}

.qualDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.qualImage {
  height: 200px;
  width: 120px;
  margin-right: 20px;
}

.textAboutUs {
  width: 60%;
  display: flex;
  margin: auto;
  margin-bottom: 50px;
}

.titleAboutUs {
  margin-top: 100px !important;
  margin-bottom: 30px !important;
  display: flex;
  /* margin: auto; */
  justify-content: space-around;
  font-size: 45px;
  /* outline: 0.5rem solid; */
  width: 60%;
  margin: auto;
  border-top: 5px solid black;
  border-left: 5px solid black;
}

@media screen and (max-width: 1000px) {
  .titleAboutUs {
    font-size: 40px;
  }
}

@media screen and (max-width: 775px) {
  .titleAboutUs {
    font-size: 35px;
    width: 80%;
  }
  .textAboutUs {
    font-size: 15px;
    width: 80%;
  }
  .coiButton {
    font-size: 14px;
  }
}

@media screen and (max-width: 600px) {
  .titleAboutUs {
    font-size: 30px;
  }
  .textAboutUs {
    font-size: 12px;
  }
}

@media screen and (max-width: 510px) {
  .titleAboutUs {
    font-size: 25px;
  }
  .coiButton {
    font-size: 12px;
  }
  .qualImage {
    height: 150px !important;
    width: 100px;
  }
}

@media screen and (max-width: 425px) {
  .titleAboutUs {
    font-size: 20px;
  }
  .textAboutUs {
    font-size: 10px;
  }
}

@media screen and (max-width: 350px) {
  .titleAboutUs {
    font-size: 17px;
  }
  .textAboutUs {
    font-size: 9px;
  }
  .coiButton {
    font-size: 8px;
  }
  .qualImage {
    height: 120px !important;
    width: 80px;
  }
}
