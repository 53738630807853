.cardWhyUs {
  width: 40%;
  display: flex;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.7);
  margin-bottom: 50px;
  border-radius: 30px;
  border-radius: 30px;
}

.listItemsWhy {
  background-color: rgba(255, 255, 255, 0);
  font-size: 20px;
  display: flex;
}

.whyTitle {
  color: #9ab07b;
  font-weight: bold;
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 40px;
}

.checkmark {
  margin-left: 10px;
  margin-right: 10px;
  height: 50px;
  width: 50px;
}

@media screen and (max-width: 1000px) {
  .cardWhyUs {
    font-size: 40px;
  }
}

@media screen and (max-width: 775px) {
  .cardWhyUs {
    font-size: 15px;
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
  .whyTitle {
    font-size: 30px;
  }
  .listItemsWhy {
    font-size: 20px;
  }
}

@media screen and (max-width: 510px) {
}

@media screen and (max-width: 425px) {
  .whyTitle {
    font-size: 25px;
  }
  .checkmark {
    height: 30px;
    width: 30px;
  }
  .listItemsWhy {
    font-size: 18px;
  }
}

@media screen and (max-width: 350px) {
  .checkmark {
    height: 30px;
    width: 30px;
  }
  .listItemsWhy {
    font-size: 15px;
  }
}
