.IPMContainer {
  position: relative;
  /* width: 100%; */
  overflow: hidden;
}

.backgroundImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: -1;
  background-position: center;
}

.textIPM {
  width: 60%;
  display: flex;
  margin: auto;
  margin-bottom: 50px;
  background-color: rgba(255, 255, 255, 0.7);
}

.titleIPM {
  margin-top: 100px !important;
  margin-bottom: 30px !important;
  display: flex;
  /* margin: auto; */
  justify-content: space-around;
  font-size: 45px;
  /* outline: 0.5rem solid; */
  width: 60%;
  margin: auto;
  border-top: 5px solid black;
  border-left: 5px solid black;
}

.ulBullets li {
  font-size: 1em;
}

@media screen and (max-width: 1000px) {
  .titleIPM {
    font-size: 40px;
  }
}

@media screen and (max-width: 775px) {
  .titleIPM {
    font-size: 35px;
    width: 80%;
  }
  .textIPM {
    font-size: 15px;
    width: 80%;
  }
  .ulBullets li {
    font-size: 15px;
  }
}

@media screen and (max-width: 600px) {
  .titleIPM {
    font-size: 30px;
  }
  .textIPM {
    font-size: 12px;
  }
  .ulBullets li {
    font-size: 12px;
  }
}

@media screen and (max-width: 510px) {
  .titleIPM {
    font-size: 25px;
  }
}

@media screen and (max-width: 425px) {
  .titleIPM {
    font-size: 20px;
  }
  .textIPM {
    font-size: 10px;
  }
  .ulBullets li {
    font-size: 10px;
  }
}

@media screen and (max-width: 350px) {
  .titleIPM {
    font-size: 17px;
  }
  .textIPM {
    font-size: 9px;
  }
  .ulBullets li {
    font-size: 9px;
  }
}
